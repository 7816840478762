.project {
  padding: 2em;
  margin: 0 auto;
  text-align: center;
  box-shadow: var(--shadow);
  transition: transform 0.2s linear;
  width: 25vw;
}
/* media query  tablet and phone for project class  */

@media only screen and (max-width: 1200px) {
  .project {
    max-width: 50vw;
  }
}
@media only screen and (max-width: 768px) {
  .project {
    max-width: 90vw;
  }
}

.project:hover {
  transform: translateY(-7px);
}

.project__description {
  margin-top: 1em;
}

.project .link--icon {
  margin-left: 0.5em;
}
