* {
    padding: 0;
    margin: 0;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

html {
    scroll-behavior: smooth;
}

h1,
h2,
h3,
h4 {
    line-height: 1.2;
    color: var(--clr-fg-alt);
}

h1 {
    font-size: 4rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.5rem;
}

h4 {
    font-size: 1.3rem;
}

ul {
    list-style-type: none;
}

a {
    text-decoration: none;
}

button {
    cursor: pointer;
}

@media (max-width: 900px) {
    h1 {
        font-size: 2.6rem;
    }
}
